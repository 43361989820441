///// Shadow Variable //////////
$shady_shadow: 0 2px 6px -1px rgba($dark, 0.08);

//// BODY STYLE OVERRIDE 
////////////////////////////////
.nk-body{
    &.ui-shady{
        background: $white !important;
    }
}

// SHADY STYLE
.ui-shady{
    .table .table-light th {
        background: rgba($lighter, .5);
        box-shadow: 0 -1px 5px -5px rgba($dark, 0.7);
    }
    .tb-tnx-head, .sp-plan-action, .tb-ticket-item.is-unread, .support-topic-block:link:hover, .nk-refwg-stats {
        background: rgba($lighter, .5);
    }
    .nk-refwg-stats {
        background: rgba($lighter, .5) !important;
    }
    .card .card-inner + .table .tb-tnx-head {
        border-top: 1px solid $border-color;
    }

    .card,
    .code-block,
    .nk-iv-wg1,
    .accordion:not(.accordion-s2):not(.accordion-s3),
    .nk-reply-form,
    .nk-files-view-grid .nk-file, .nk-files-view-group .nk-file, .nk-files-view-list .nk-files-list, 
    .chat-msg, .kanban-board-header, .kanban-item, .kanban-add-task 
    {
        box-shadow: $shady_shadow;
    }
    .card .accordion:not(.accordion-s2):not(.accordion-s3) {
        box-shadow: none;
        border: 1px solid $border-color;
    }
    .nk-header {
        box-shadow: 0 0 4px -1px rgba($darker, .15);
    }
}

// Apps
.ui-shady {
    .nk-msg-boxed, .nk-ibx-boxed, .nk-fmg-boxed, .nk-chat-boxed{
        box-shadow: $shady_shadow;
    }
}

.ui-shady{
    .nk-msg-aside, .nk-msg-nav{
        background: $white;
    }
    
    .nk-msg-reply, .nk-chat-panel, .nk-ibx-reply {
        box-shadow: inset 0 0 8px -5px rgba($darker, .3);
    } 
    .nk-ibx-item {
        box-shadow: 0 0 7px -1px rgba($darker, .1);
    } 
}
.ui-rounder{
    .card,.ratio{
        border-radius: $border-radius-xxl;
    }
}