@font-face {
  font-family: "inter-font";
  src: url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans:wght@400;500;600;700&display=swap");
}

/*=============================================
  =            common buttons start here            =
  =============================================*/

.primary-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 2147483647px;
  background-color: #00c050;
  transition: 0.2s;
  will-change: transform, color, background-color, border-color;
  outline: none;
  cursor: pointer;
  width: 100%;
  padding: 6px 24px;
  &[disabled] {
    opacity: 0.5;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    // color: rgba(var(--mg__secondary), 1);
    width: 100%;
    white-space: nowrap;
    font-weight: 500;
    text-align: center;
    transition: all 0.2s ease;
    font-size: 16px;
    line-height: 28px;
  }
}

.social-btn {
  outline: none;
  cursor: pointer;
  width: 100%;
  padding: 6px 24px;
  position: relative;
  box-sizing: border-box;
  transition: 0.2s;
  border-radius: 2147483647px;
  border: 1px solid rgb(189, 189, 189);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &:hover {
    background-color: rgb(238, 238, 238);
  }
  .icon-box {
    position: absolute;
    left: 24px;
  }
  span {
    line-height: 28px;
    font-size: 16px;
  }
}

.primary-button-wallet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.016rem;
  padding: 8px 12px;
  border-radius: var(--oui-btn-primary-border-radius, 0.5rem);
  background-color: #edeef2;
  height: 56px;
  color: rgb(82, 100, 132);
  &:hover {
    color: rgb(82, 100, 132);
  }
}

.back_navigation {
  position: absolute;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 2px; */
  cursor: pointer;
  background: #efefef;
  left: 15px;
}

.backdrop-layer {
  background-color: rgba(24, 23, 26, 0.2);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 28px;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 1;
  height: 100%;
  &.hide {
    display: none;
  }
}

.close-down-arrow {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  cursor: pointer;
  background: #efefef;
}

.transparent-btn {
  text-align: center;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.swap-balance-info {
  font-size: 13px;
}

.loader-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*=====  End of common buttons start here  ======*/


/*=============================================
=            common css codes here            =
=============================================*/

.font-w-500 {
  font-weight: 500;
}

.cursor_pntr {
  cursor: pointer;
}

/*=====  End of common css codes here  ======*/



.page-layout {
  .ahrvo-card {
    position: relative;
    top: 48px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px 32px 32px 32px;
    width: 400px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    box-shadow: 0 12px 56px rgba(119, 118, 122, 0.15);
    border-radius: 28px;
    background-color: #fff;
    overflow: hidden;
    min-height: 550px;
    padding-bottom: 110px;
    .card_header {
      width: 100%;
      //   height: 64px;
      box-sizing: border-box;
      padding: 16px;
      .header_text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        div {
          span {
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5rem;
            font-family: inter-font, sans-serif;
            color: #00c050;
          }
        }
      }
      .header_logo {
        width: 50px;
        height: 50px;
        margin: auto;
        margin-top: 5px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      p {
        font-size: 14px;
        text-align: center;
        margin-top: 32px;
        span {
          font-weight: 700;
        }
      }
    }
    .card_footer {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // gap: 5px;
      margin-top: 16px;
      text-align: center;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, -50%);
      span {
        color: #b6b4ba;
        font-size: 12px;
      }
      .footer_logo_img {
        width: 30px;
        margin: auto;
      }
      .footer_logo {
        color: #b6b4ba;
        font-weight: bold;
        font-size: 14px;
        img {
          width: 20px;
          vertical-align: sub;
          object-fit: contain;
          margin-right: 6px;
        }
      }
    }
  }
}

.primary-form-group {
  label {
    color: #3e3d3d;
  }
  input {
    border: 1px solid rgb(189, 189, 189);
    border-radius: 2147483647px;
    font-size: 16px;
    padding: 6px 16px;
    width: 100%;
    color: #000;
    &:focus-visible {
      outline: #00c050 auto 1px;
    }
  }
}

.page-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 32px;
  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgb(117, 117, 117);
  }
}
.custom-dot {
  width: 5px;
  height: 5px;
  background-color: rgb(117, 117, 117);
  border-radius: 50%;
}

.otp-verification-box {
  margin-top: 32px;
  .header_logo {
    display: flex;
    justify-content: center;
  }
  .verification-msg {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #000;
    margin-top: 24px;
    div {
      font-weight: 600;
      color: #000;
    }
    p {
      font-size: 13px;
      line-height: normal;
      margin-top: 10px;
      color: #77767a;
    }
  }
  .otp-form-group {
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    input {
      transition: all 0.2s linear;
      width: 40px !important;
      height: 48px;
      border: 1px solid #e2e0e6;
      border-radius: 10px;
      box-sizing: border-box;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      background-color: transparent;
      text-shadow: 0 0 0 #18171a;
      &:focus-visible {
        outline: #00c050 auto 1px;
      }
      &:nth-of-type(3) {
        margin-right: 24px;
      }
    }
  }
}

.error_msg {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(14 98 159);
  margin-bottom: 30px;
  text-decoration: underline;
  cursor: pointer;
}

.social-login-group {
  ul {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    gap: 16px 8px;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-y: hidden;
    max-height: 73px;
    padding-top: 15px;
    padding-bottom: 10px;
    transition: max-height 0.3s;
    transition-timing-function: cubic-bezier(0, 0.73, 0.71, 1);
    &.allSocialIcons {
      max-height: 330px;
    }
    li {
      width: 102px;
      height: 44px;

      background-color: rgb(209, 213, 219);
      border-radius: 9999px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 20px;
        transition: display 0.15s;
        transition-timing-function: cubic-bezier(0, 0.54, 0.63, 0.99);
      }
      .imgIcon {
        display: block;
      }
      .hoverIcon {
        display: none;
      }
      &:hover {
        background-color: rgb(226, 227, 229);
        .imgIcon {
          display: none;
        }
        .hoverIcon {
          display: block;
        }
      }
    }
  }
  .info {
    font-size: 12px;
    margin-bottom: 0;
  }
  .viewMore {
    text-align: end;
    margin-top: 4px;
    font-size: 14px;
    color: blue;
    cursor: pointer;
  }
  &.external-wallet {
    ul {
      li {
        img {
          width: 33px;
        }
      }
    }
  }
}

.extrnal-links-box {
  p {
    color: #3e3d3d;
  }
}
.back-btn {
  color: rgb(107, 114, 128);
  display: inline-flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.external-wallet-area {
  .qr-code-area {
    .qr-code-msg {
      font-size: 11px;
      color: rgb(107, 114, 128);
      text-align: center;
    }
    .qr-code-img {
      width: 220px;
      height: 220px;
      margin: auto;
    }
  }
}

.screenAtAllSocial {
  max-height: 424px;
  overflow-y: auto;
  margin-right: -15px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/*=============================================
  =            tooltip code here            =
  =============================================*/

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .custom-tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: rgb(249, 250, 251);
  color: rgb(156, 163, 175);
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 2;
  top: 115%;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  //   word-break: break-word;
  //   white-space: nowrap;
}
.custom-tooltip:hover::after {
  display: none;
}

.custom-tooltip::after {
  content: "";
  position: absolute;
  bottom: -24%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  //   border-color: #3989f8 transparent transparent transparent;
  transform: rotate(0deg);
  display: none;
}

.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
  opacity: 1;
}

/*=====  End of tooltip code here  ======*/

/*=============================================
  =            balance section to match magic screen            =
  =============================================*/

.available-balance-box {
  text-align: center;
  h2 {
    font-weight: 800;
    font-size: 32px;
    line-height: 2.75rem;
    color: #000;
  }
}

.action-buttons-money {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  .action_button {
    span {
      border: none;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      background-color: #efefef;
    }
    p {
      text-align: center;
      margin-top: 10px;
      color: #526484;
    }
  }
}

.selected-currency {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  .currency-text-side {
    display: flex;
    align-items: center;
    gap: 8px;

    .currency-logo {
      img {
      }
    }

    .currency-name {
      font-weight: 800;
    }
  }

  .currency-value-side {
    p.currency_value {
      margin-bottom: 0;
      font-weight: 800;
    }

    p.currency_value_text {
    }
  }
}

.payment-method-box {
  .method-heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: #000;
  }
}

.bank-payment-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8fa;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  border: none;
  width: 100%;
  margin-bottom: 10px;
  &:hover {
    background: #dddbe0;
  }
  .button-box {
    display: flex;
    align-items: center;
    gap: 10px;
    .bank-method {
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      color: #18171a;
    }
  }
}

.provider-selection-box {
  position: absolute;
  z-index: 2;
  background: #fff;
  width: 100%;
  left: 0;
  bottom: 0px;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -56px 56px rgba(119, 118, 122, 0.15);
  padding: 15px 30px;
  transition: 0.3s;
  .provider-selection-header {
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .provider-heading {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #000;
    }
  }
  &.hide-selection-box {
    bottom: -100%;
  }
  &.h-95 {
    height: 95%;
  }
  .provider-options {
    .provider-option {
      border: 1px solid #dddbe0;
      padding: 12px 16px;
      margin-top: 12px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      .provider-option-left {
        .provider-icon {
          img {
            width: 100px;
            height: 21px;
            object-fit: contain;
          }
        }
      }
      div {
        color: #77767a;
      }
    }
  }
}
.search-header {
  display: flex;
  width: 100%;
  position: relative;
  .ni-search {
    position: absolute;
    left: 10px;
    top: 50%;
    z-index: 1;
    transform: translate(0, -50%);
  }
  input {
    width: 100%;
    border-radius: 30px;
    padding-left: 30px;
    position: relative;
    height: 50px;
    border: 1px solid #adaaaa;
  }
}
.parent-result {
  margin-top: 2rem;
  .all {
    border-bottom: 1px solid #adaaaa;
    font-size: 16px;
    margin-bottom: 2rem;
  }
  li {
    display: flex;
    margin-bottom: 15px;
    a {
      display: flex;
    }
  }
  .userAvatar {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #080606;
    background: #e5e5e5;
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    border-radius: 50%;
    margin-right: 15px;
  }
  .userName-parent {
    .userName {
      color: #080606;
      font-size: 15px;
      font-weight: 500;
    }
    .time {
      color: #57575b;
    }
  }
}
.transfer-heaadig {
  color: #080606;
  margin-top: 3rem;
}
.content-text {
  margin-top: 2rem;
  color: gray;
  text-align: center;
}
.form-parent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  form {
    max-width: 450px;
    .primary-button-wallet {
      color: unset;
    }
    .custom-select {
      font-size: 13px;
      border-radius: 15px;
      height: 50px;
      .react-dropdown-select-content {
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .react-dropdown-select-dropdown {
        /* padding: 15px; */
        /* border-top-left-radius: 15px; */
        border: unset;
        box-shadow: unset;
        max-height: unset;
        height: 200px;
        top: 50px;
        .react-dropdown-select-item {
          padding: 12px 20px;
          &:hover {
            background: #dae0d587;
            border-radius: 15px;
            color: #000000bd;
          }
        }
        .react-dropdown-select-item-selected {
          background: #dae0d587;
          border-radius: 15px;
          color: #000000bd;
        }
      }
    }
    .css-wmy1p7-ReactDropdownSelect:focus-within {
      box-shadow: 0 0 0 3px rgb(146 149 152 / 20%);
      border-color: rgb(79, 78, 78);
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }
    .css-wmy1p7-ReactDropdownSelect:hover {
      border-color: rgb(79, 78, 78);
    }
  }
  .form-heading {
    color: gray;
    text-align: center;
    font-size: 10px;
    margin-bottom: 10px;

  }
}
.crypto-review {
  min-height: unset !important;
}
.note {
  background: #dae0d587;
  color: dimgray;
  font-size: 10px;
  padding: 15px;
  border-radius: 10px;
}
.details-parent {
  border: 1px solid #e5e5e5;
  // height: 500px;
  margin-top: 10px;
  border-radius: 5px;
  .transfer-details,
  .recipient-details {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px;
    ul {
      padding: 0px;
      li {
        display: flex;
        justify-content: space-between;
        // margin-bottom: 10px;
        span {
          color: dimgray;
          font-size: 10px;
        }
        .money {
          color: black;
          font-size: 16px;
          font-weight: 500;
        }
        .guaranty {
          font-weight: 500;
          color: black;
          text-decoration: underline;
        }
      }
      .transfer-heading {
        span {
          color: black;
          font-weight: 500;
          font-size: 10px;
        }
      }
    }
  }
}
.message {
  color: dimgray;
  font-size: 10px;
  margin-top: 20px;
}
.checkbox-parent {
  margin-top: 10px;
  border: 1px solid rgb(156, 154, 154);
  padding: 3px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  input {
    margin-right: 10px;
  }
  span {
    font-size: 10px;
    color: dimgray;
  }
}
.qr-code-box {
  width: 180px;
  height: 180px;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.currency-selection-box {
  padding: 10px 16px 14px;
  gap: 0.5rem;
  background: #f6f7f9;
  border: 0.1px solid transparent;
  border-radius: 8px;
  &.error {
    border: 0.1px solid #e62e2e;
  }
  .currency-selection-box-heading {
    font-size: 16px;
    line-height: 24px;
  }
  .selection_box_area {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    .currency_input {
      width: 8.25rem;
      padding: 0;
      height: 2rem;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: -0.016rem;
      color: #141519;
      flex: none;
      order: 0;
      flex-grow: 1;
      border: none;
      -webkit-tap-highlight-color: transparent;
      background: none;
      outline: none;
    }
    .country_select_box {
      position: relative;
      select {
        align-items: center;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: -0.016rem;
        padding: 8px 12px;
        border-radius: 0.5rem;
        border: none;
        outline: none;
        background-color: #fff;
      }
      .demo-box {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 11;
        cursor: pointer;
      }
    }
  }
}

.other-payments-area {
  display: flex;
  gap: 10px;
  .other-payment-box {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 0px;
    gap: 0.125rem;
    border: 1px solid var(--oui-icon-btn-border-color, #e6dcdc);
    border-radius: var(--oui-icon-btn-border-radius, 0.5rem);
    cursor: pointer;
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: unset;
    }
  }
}

.send-money-area {
  .currency-symb {
  }
  input {
    width: 100%;
    padding: 0;
    height: 2rem;
    // font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 2rem;
    letter-spacing: -0.016rem;
    color: #141519;
    /* flex: none; */
    /* order: 0; */
    /* flex-grow: 1; */
    border: none;
    -webkit-tap-highlight-color: transparent;
    background: none;
    outline: none;
    margin: auto;
    text-align: center;
  }
}
.basic-btn {
  padding: 7px 10px;
  background: #efefef;
  width: 120px;
  text-align: center;
  border-radius: 21px;
  margin: auto;
  margin-top: 20px;
}

.send-balance-form-box {
  border: 1px solid #efefef;
  border-radius: 8px;
  margin-top: 30px;
  .send-balance-form-group {
    display: flex;
    border-bottom: 1px solid #efefef;
    padding: 15px 12px;
    label {
      width: 110px;
    }
    .send-balance-input-group {
      display: flex;
      gap: 10px;
      align-items: center;
      line-height: 1;
      .icon-img {
        width: 18px;
      }
      input {
        border: none;
        outline: none;
      }
      .filled-value {
        font-weight: 500;
      }
    }
  }
}

/*=====  End of balance section to match magic screen  ======*/

/*=============================================
=            code to override country select dropdown            =
=============================================*/
.provider-selection-box {
  .css-2b097c-container {
    .css-yk16xz-control,
    .css-1pahdxg-control {
      border: 1px solid #cfc9c9 !important;
      &:hover {
        border-color: #cfc9c9;
      }
      .css-g1d714-ValueContainer {
        .css-1uccc91-singleValue {
          display: none;
        }
      }
    }
    .css-26l3qy-menu {
      // background-color: red;
      height: 460px;
      box-shadow: none;
      .css-4ljt47-MenuList,
      & > div {
        max-height: 100%;
        & > div {
          padding: 10px 5px;
          &.css-hn1mry-option {
            background-color: #a3cbff;
          }
        }
      }
    }
  }
}

.custom-option-listing {
  display: flex;
  align-items: center;
  gap: 10px;
  .img-box {
    width: 30px;
    height: 30px;
    border: 1px solid #e6dcdc;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: contain;
    }
  }
  .listing_text_box {
    .option-heading {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      /* line-height: 1.5rem; */
      color: #57575b;
      text-transform: uppercase;
    }
    .option-subheading {
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.7rem;
      color: #6b6f80;
    }
  }
}

/*=====  End of code to override country select dropdown  ======*/

.currency_select_box {
  position: relative;
  select {
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    // font-family: "Inter", sans-serif;
    font-style: normal;
    // font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.016rem;
    padding: 8px 12px;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    background-color: #fff;
    text-transform: uppercase;
  }
  .demo-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 11;
    cursor: pointer;
  }
  img {
    margin-bottom: 5px;
    vertical-align: middle;
    border: 1px solid #e6dcdc;
    width: 23px;
    height: 23px;
    border-radius: 50%;
  }
}
/*=============================================
    =            Swap screen code here            =
    =============================================*/

.primary-swap-screen {
  .swap-form-group {
    label {
      font-size: 16px;
      font-weight: 600;
    }
    .swap-input-group {
      .currency_select_box {
        select {
          border: 1px solid rgb(205, 199, 199);
          height: 50px;
          width: 100%;
          color: #526484;
        }
      }
      &.input-with-select {
        display: flex;
        .currency_select_box {
          flex: 1;
          select {
            border: 1px solid rgb(205, 199, 199);
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 50px;
            width: 100%;
          }
        }
        input {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          margin-left: -1px;
          border: 1px solid rgb(205, 199, 199);
          color: #526484;
          outline: none;
          padding-left: 10px;
        }
      }
    }
  }
}

.swap-action {
  img {
    cursor: pointer;
  }
}

.amount-value-heading {
  font-size: 36px;
  text-align: center;
  color: #000;
}

.amount-value-subheading {
  font-size: 16px;
  text-align: center;
}
/*=====  End of Swap screen code here  ======*/


/*=============================================
=            custom tramsaction table            =
=============================================*/

.custom-transaction-table {
  margin-left: -0.5px;
  margin-right: -1px;
  .sc-fLcnxK {
    .rdt_Table {
      margin-top: -1px;
      .rdt_TableBody {
        .rdt_TableRow {

          .rdt_TableCell {
            // flex-basis: auto;
            // min-width: 0;
            padding-left: 8px;
            padding-right: 0;
            .nk-tb-col {
              &:last-child {
                padding-right: 0;
              }
              &:first-child {
                padding-left: 0;
              }
              span {
                &:not(.badge){
                  display: block;
                  white-space: nowrap;
                  line-height: 1.4;
                  font-size: 13px;
                  span {
                    display: inline-block;
                  }
                }
                &:nth-of-type(2) {
                  &:not(.badge){
                    color: #8094ae;
                    font-size: 11.7px;
                  }
                }
              }
              .nk-tb-actions {
                .nk-tb-action- {
                  display: none;
                }
              }
            }
          }
          &:hover {
            .rdt_TableCell {
              .nk-tb-col {
                .nk-tb-actions {
                  .nk-tb-action- {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
      .rdt_TableHead {
        .rdt_TableHeadRow {
          .rdt_TableCol {
            .rdt_TableCol_Sortable {
              justify-content: center !important;
              div.hbotQl {
                font-size: 11.7px;
                color: #8094ae;
                font-weight: normal;
              }
              .__rdt_custom_sort_icon__ {
                font-size: 10px;
                letter-spacing: -2px;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}


/*=====  End of custom tramsaction table  ======*/



/*=============================================
=            Nav bar collapse on desktop            =
=============================================*/

.side_collapser {
  line-height: 28px;
  font-size: 28px;
  padding: 0;
  cursor: pointer;
}

// @media (min-width: 1200px) {
//   body.collapse_sidebar {
//     .nk-sidebar {
//       width: 84px;
//     }
//     .nk-sidebar + .nk-wrap {
//       padding-left: 84px;
//       .nk-header {
//         left: 84px;
//       }
//     }
//   }
// }

/*=====  End of Nav bar collapse on desktop  ======*/

